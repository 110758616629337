<template>
	<div>
		<!-- <Swiper /> -->
		<div class="container">
			<div class="layout-two">
				<section class="layout-left">
					<ServerList />
				</section>

				<section class="layout-right pc">

					<input type="text" v-model="$store.state.server_search" class="search mb-50" placeholder="서버이름 검색" />
					<!-- 장르 -->
					<div class="genre">
						<h4>장르</h4>
						<el-checkbox
							v-model="$store.state.check_all_genres"
							@change="handleCheckAllGenreChange"
							>모든 분류</el-checkbox
						>
						<el-checkbox-group
							v-model="$store.state.checked_genres"
							@change="handleCheckedGenreChange"
						>
							<el-checkbox v-for="item in genres" :label="item" :key="item">{{
								item
							}}</el-checkbox>
						</el-checkbox-group>
					</div>
					<div class="division"></div>
					<!-- 버전 -->
					<div class="version">
						<h4>버전</h4>
						<el-checkbox
							v-model="$store.state.check_all_versions"
							@change="handleCheckAllVersionChange"
							>모든 버전</el-checkbox
						>
						<el-checkbox-group
							v-model="$store.state.checked_versions"
							@change="handleCheckedVersionChange"
						>
							<el-checkbox v-for="item in versions" :label="item.id" :key="item.id">{{
								item.name
							}}</el-checkbox>
						</el-checkbox-group>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
// import Swiper from '@/components/swiper'
import ServerList from '@/components/server-list'

const genre_options = [
	'스카이그리드',
	'바닐라',
	'타우니',
	'Spigot',
	'야생',
	'미니게임',
	'전쟁',
	'펙션',
	'PVP',
	'건축',
	'경제',
	'마인팜',
	'스카이블럭',
	'RPG',
	'인생약탈',
	'mcMMO',
	'쿠키런',
	'Sponge',
	'성인',
	'정품',
	'포켓몬'
]

const version_options = [
	{name: '1.19', id: 34},
	{name: '1.18', id: 33},
	{name: '1.17', id: 32},
	{name: '1.16', id: 31},
	{name: '1.15', id: 30},
	{name: '1.14', id: 29},
	{name: '1.13', id: 28},
	{name: '1.12', id: 27},
	{name: '1.11', id: 26},
	{name: '1.10', id: 25},
	{name: '1.9', id: 24},
	{name: '1.8', id: 23},
	{name: '1.7', id: 22},
	{name: '1.6', id: 21},
	{name: '1.5', id: 20},
]

export default {
	components: {
		// Swiper,
		ServerList,
	},
	data() {
		return {
			genres: genre_options,
			versions: version_options,
		}
	},
	methods: {
		handleCheckAllGenreChange(val) {
			this.$store.state.checked_genres = val ? genre_options : []
		},
		handleCheckedGenreChange(val) {
			let checked_count = val.length
			this.$store.state.check_all_genres = checked_count === this.genres.length
		},
		handleCheckAllVersionChange(val) {
			this.$store.state.checked_versions = val ? [20,21,22,23,24,25,26,27,28,29,30,31,32,33,34] : []
		},
		handleCheckedVersionChange(val) {
			let checked_count = val.length
			this.$store.state.check_all_versions = checked_count === this.versions.length
		},
	},
}
</script>

<style scoped>
table .img-wrap {
	width: 50px;
}

.el-checkbox {
	width: 50%;
	margin: 0;
	padding-bottom: 15px;
}

h4 {
	margin-bottom: 10px;
}

.badge-wrap .badge:first-child {
	background-color: #9086ff;
}

.server-list td {
	padding: 15px;
}
.server-list .server-num {
	float: left;
	padding-right: 20px;
}

.server-list .server-image {
	border-radius: 5px;
	width: 50px;
	float: left;
	overflow: hidden;
	padding-right: 10px;
}

.server-list .server-name {
	width: 160px;
	text-align: left;
	float: right;
}

.server-list .server-name .badge-wrap {
	padding-top: 5px;
}

.layout-two {
	overflow: hidden;
}

.layout-two .layout-left {
	float: left;
	width: 75%;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	padding: 20px;
	box-sizing: border-box;
}

.layout-two .layout-right {
	float: right;
	width: 23%;
	background-color: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 5px;
	padding: 20px;
	box-sizing: border-box;
}

@media (max-width: 1200px) {
	.layout-two .layout-left {
		width: 70%;
	}
	.layout-two .layout-right {
		width: 28%;
	}
}

@media (max-width: 768px) {
	.layout-two .layout-left,
	.layout-two .layout-right {
		width: 100%;
	}
	.layout-left {
		margin-bottom: 20px;
	}
}
</style>
