<template>
	<div class="server-list">
		<div class="pc">
			<div class="table-sort mb-30">
				<h4>
					전체
					<span class="color-main">{{ total }}</span>
				</h4>
				<div class="btn-wrap">
					<button @click="clearSearchOptions" class="btn-inactive btn-medium">새로고침</button>
					<button @click="gotoadmin" class="btn-main btn-medium ml-20" v-if="$store.state.user.loggedIn">서버 등록</button>

				</div>
			</div>
			<div class="pc-table">
				<table>
					<thead>
						<tr>
							<td>순위</td>
							<td>서버이름</td>
							<td>플레이어</td>
							<td>추천</td>
						</tr>
					</thead>
					<tbody>
						<template v-for="(item, idx) in server_list">
							<tr :key="'first_'+idx"
								@click="$router.push('/server/'+item.host)"
								class="cursor-pointer server-list-item"
								:style="item.rank == 0 ? 'background-color: #defff6;' : ''"
							>
								<td rowspan="1" v-if="item.rank == 0"><img :src="require('@/assets/star.png')" style="width: 50%;" /> </td>
								<td rowspan="1" v-else>{{ item.rank }}</td>

								<td>
									<div class="server-name" style="max-width: 28%;">
										<strong style="font-size: 16px; word-break: break-all; line-break: anywhere;"><img class="statusimage" :src="item.status ? require(`@/assets/images/green.png`) : require(`@/assets/images/red.png`) " /> {{ item.name }}</strong>
										<div class="badge-wrap">
											<span
												class="badge badge-grey"
												v-for="type in item.types"
												:key="type"
												>{{ type }}</span
														>
										</div>
									</div>
									<div class="server-image-wrap">
										<div :key="'second_'+idx" class="cursor-pointer server-image" @click="$router.push('/server/'+item.host)">
											<div>
												<center>
												<img class="full" :src="item.banner ? item.banner : require('@/assets/images/nobanner_new.png')" />
												</center>
											</div>
										</div>
										<div class="server-desc">
											<span class="server-version">{{item.version}}</span>
											<span class="server-url">
												<span style="padding-right: 3px;">
													<b>{{ item.host + (item.port == 25565 ? "" : ":"+item.port) }}</b>
												</span>
												<!-- <div class="icon-small">
													<img src="@/assets/images/icon-copy.svg" class="full" alt="복사 아이콘" />
												</div> -->
											</span>
											
										</div>
									</div>
								</td>
							
								<td>
									<b>{{ item.players_online }}</b> / <small>{{item.players_total}}</small>
								</td>
								<td class="table-pc">{{ item.votes.toLocaleString() }}</td>
							</tr>
						</template>
					</tbody>
					<!-- <tbody v-if="!table_load">
						<tr style="border: 0">
							<td colspan="7"><center><br/><img class="throbber" :src="require(`@/assets/images/throbber.gif`)" /></center></td>
						</tr>
					</tbody> -->
				</table>
			</div>
			<div class="tablet-table">
				<ul>
					<li
						@click="$router.push('/server/'+item.host)"
						v-for="(item, idx) in server_list"
						:key="idx"
						class="server-list-item cursor-pointer"
						:style="item.rank == 0 ? 'background-color: #defff6;' : ''"
					>
						
						<div class="server-name">
							<span rowspan="1" class="server-rank" v-if="item.rank == 0"><span class="el-icon-star-on"></span></span>
							<span rowspan="1" class="server-rank" v-else>{{ item.rank }}</span>
							<strong style="font-size: 16px; word-break: break-all; line-break: anywhere;"><img class="statusimage" :src="item.status ? require(`@/assets/images/green.png`) : require(`@/assets/images/red.png`) " /> {{ item.name }}</strong>
							<div class="badge-wrap">
								<span
									class="badge badge-grey"
									v-for="type in item.types"
									:key="type"
									>{{ type }}</span
											>
							</div>
						</div>

						<div class="server-image-wrap">
							<div class="server-image">
								<img class="full" :src="item.icon == null ? require(`@/assets/images/server.webp`) : item.icon" />
							</div>
						</div>
						<div class="server-desc">
							<ul>
								<li>
									<span class="color-grey">버전</span>
									<span>{{ item.version }}</span>
								</li>
								<li>
									<span class="color-grey">주소</span>

									<span class="server-url">
										<span><b>
											{{ item.host + (item.port == 25565 ? "" : ":"+item.port) }}</b>
										</span>
									</span>
								</li>
								<li>
									<span class="color-grey">플레이어</span>
									<span>{{ item.players_online }}</span>
								</li>
								<li>
									<span class="color-grey">추천</span>
									<span>{{ item.votes }}</span>
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<!-- <p v-if="!table_load">
					<center><br/><img class="throbber" :src="require(`@/assets/images/throbber.gif`)" /></center>
				</p> -->
				<el-pagination v-if="table_load" layout="prev, pager, next" :total="total" :page-size="15" :current-page="page" @current-change="changePage"> </el-pagination>

			</div>
		</div>

		<div class="mobile">
			<div class="table-sort mb-30">
				<h4>
					전체
					<span class="color-main">{{ total }}</span>
				</h4>
				<div class="btn-wrap">
					<button @click="clearSearchOptions" class="btn-inactive btn-medium">새로고침</button>
					<button @click="gotoadmin" class="btn-main btn-medium ml-20" v-if="$store.state.user.loggedIn">서버 등록</button>
				</div>
			</div>

			<ul>
				<li
					@click="$router.push('/server/'+item.host)"
					v-for="(item, idx) in server_list"
					:key="idx"
					class="server-list-item cursor-pointer"
					:style="item.rank == 0 ? 'background-color: #defff6;' : ''"
				>
				
					<div class="server-name">
							<span rowspan="1" class="server-rank" v-if="item.rank == 0"><span class="el-icon-star-on"></span></span>
							<span rowspan="1" class="server-rank" v-else>{{ item.rank }}</span>
							<strong style="font-size: 16px; word-break: break-all; line-break: anywhere;"><img class="statusimage" :src="item.status ? require(`@/assets/images/green.png`) : require(`@/assets/images/red.png`) " /> {{ item.name }}</strong>

						<div class="badge-wrap">
								<span
									class="badge badge-grey"
									v-for="type in item.types"
									:key="type"
									>{{ type }}</span
								>
						</div>
					</div>
					<div class="server-image-wrap">
							<div class="server-image">
								<img class="full" :src="item.icon == null ? require(`@/assets/images/server.webp`) : item.icon" />
							</div>
						</div>
						<div class="server-desc">
							<ul>
								<li>
									<span class="color-grey">버전</span>
									<span>{{ item.version }}</span>
								</li>
								<li>
									<span class="color-grey">주소</span>

									<span class="server-url">
										<span><b>
											{{ item.host + (item.port == 25565 ? "" : ":"+item.port) }}
										</b></span>
				
									</span>
								</li>
								<li>
									<span class="color-grey">플레이어</span>
									<span>{{ item.players_online }}</span>
								</li>
								<li>
									<span class="color-grey">추천</span>
									<span>{{ item.votes }}</span>
								</li>
							</ul>
						</div>

				</li>
			</ul>
			<!-- <p v-if="!table_load">
				<center><br/><img class="throbber" :src="require(`@/assets/images/throbber.gif`)" /></center>
			</p> -->
			<el-pagination v-if="table_load" layout="prev, pager, next" :total="total" :page-size="15" :current-page="page" @current-change="changePage"> </el-pagination>

		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { apiUrl } from '../constants/config';
import { Loading } from 'element-ui';
var loadingInstance;

export default {
	data() {
		return {
			table_load: true,
			total: 0,
			page: 1,
			scrollPostion: 0,
			pageWidth: 0,
			server_list: [
				
			],
		}
	},
	methods: {
		scrollNext(){
			if(this.pageWidth <= 1140 || !this.pageWidth)
				return;
			if(Math.ceil(this.total/15) == this.page)
				return;
			this.page = this.page + 1;
			this.loadTable(1);
		},
		changePage(page){
			this.page = page;
			this.loadTable(0);
		},
		async loadTable(type){
			if(this.table_load == false)
				return;
			loadingInstance = Loading.service({ fullscreen: true });

			var vm = this;
			vm.table_load = false;

			if(type==0){
				vm.server_list = [];
			}

			if(type==0 || (type==1 && vm.page == 1)){
				await axios.get(apiUrl+'/server/premium')
				.then(function(response){
					vm.server_list = vm.server_list.concat(response.data.servers);

				})
				.catch(function(error) {
					console.log(error);
				});
			}

			axios.post(apiUrl+'/server/list',{
				page: vm.page,
				options: { versions: vm.$store.state.checked_versions, genres: vm.$store.state.checked_genres, search: vm.$store.state.server_search}
			})
			.then(function(response) {
				vm.server_list = vm.server_list.concat(response.data.servers);
				vm.total = response.data.total;
				vm.table_load = true;
				loadingInstance.close();
			})
			.catch(function(error) {
				console.log(error);
			});
		},
		clearSearchOptions(){
			this.$store.state.checked_versions = [];
			this.$store.state.checked_genres = [];
			this.$store.state.check_all_genres = false;
			this.$store.state.check_all_versions = false;
			this.$store.state.server_search = "";
		},
		gotoadmin(){
			document.location.href = 'https://backend.mine.page/user/gotoadmin';
		},
		handleScroll(e){ 
			const scrollHeight = document.documentElement.scrollHeight;
 			const scrollTop = document.documentElement.scrollTop;
  			const clientHeight = document.documentElement.clientHeight;
			if (scrollTop + clientHeight + 30 >= scrollHeight && this.table_load === true) {
				this.scrollNext();
			}
			
		
		},
		handleResize(){
			if(this.pageWidth == window.innerWidth)
				return;
			this.pageWidth = window.innerWidth;
			this.page = 1;
			this.loadTable(0);
		}

	},
	mounted(){
		this.loadTable(0);
		this.clearSearchOptions();
	},
	created () {
    	window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
		this.pageWidth = window.innerWidth;

  	},
	destroyed () {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.handleResize);
	},
	watch: {
		"$store.state.checked_versions": {
		handler: function(nv,ov) {
			if(ov){
			this.page = 1;
			this.loadTable(0)
			}
		},
		immediate: true // provides initial (not changed yet) state
		},
		"$store.state.checked_genres": {
		handler: function(nv,ov) {
			if(ov){
			this.page = 1;
			this.loadTable(0)
			}
		},
		immediate: true // provides initial (not changed yet) state
		},
		"$store.state.server_search": {
		handler: function(nv,ov) {
			if(ov){
			this.page = 1;
			this.loadTable(0)
			}
		},
		immediate: true // provides initial (not changed yet) state
		},
	}

}
</script>

<style scoped>

.statusimage {
	width: 8px;
	padding-bottom: 1px;	
}

.table-sort {
	overflow: hidden;
}

.table-sort h3 {
	float: left;
	padding: 5px 30px 5px 0;
}

h4 {
	float: left;
	padding: 8px 0;
}

.table-sort .btn-wrap {
	float: right;
}

.server-rank {
	padding-right: 10px;
}

.server-desc .server-url .icon-small {
	margin-left: 5px;
}

.server-list-item td {
	padding: 15px;
}
.server-list-item .server-image-wrap {
	float: right;
	overflow: hidden;
}

.server-list-item .server-image-wrap .server-image {
	width: 340px;
	height: 42px;
	border-radius: 5px;
	margin-bottom: 10px;
	overflow: hidden;
}

.server-list-item .server-image-wrap .server-desc {
	overflow: hidden;
}

.server-list-item .server-image-wrap .server-desc .server-version {
	float: left;
}

.server-list-item .server-image-wrap .server-desc .server-url {
	float: right;
}

.server-list-item .server-name {
	text-align: left;
	float: left;
	width: 160px;
}

.server-list-item .server-name .badge-wrap {
	padding-top: 5px;
}

.badge-wrap .badge:first-child {
	background-color: #9086ff;
}

.tablet-table {
	display: none;
}



@media (max-width: 1140px) {
	.pc-table {
		display: none;
	}
	.tablet-table {
		display: block;
	}
	.server-list-item {
		display: inline-block;
		width: 100%;
		border: 1px solid #e5e5e5;
		padding: 15px;
		margin-bottom: 15px;
		border-radius: 5px;
		box-sizing: border-box;
		overflow: hidden;
	}
	.server-list-item .server-image-wrap {
		width: 50px;
			border-radius: 5px;
		overflow: hidden;
	}
	.server-list-item .server-image-wrap .server-image {
		width: 100%;
		height: 50px;
	}
	
	.server-list-item .server-name {
		width: auto;
		float: left;
	}

	.server-image {
		padding-right: 15px;
	}

	.server-desc {
		overflow: hidden;
		width: 100%;
	}
	
	.server-desc li {
		overflow: hidden;
	}
	.server-desc span:first-child {
		float: left;
	}
	.server-desc span:last-child {
		float: right;
	}
	
}
</style>
